<template>
  <div>
    <breadcrumb name="Contact Us" title="Contact Us Settings" subtitle="Add" @onTitleClicked="$emit('cancel')"/>

    <vx-card :title="brand_name">
      <vs-row>
        <vs-col vs-lg="6" vs-sm="12" style="padding: 0 19px 0 19px">
          <label for="brands">Brand</label>
          <select id="brands" v-model="brand_id">
            <option value="0" disabled>Choose Brand</option>
            <option :value="brand.id" v-for="(brand, index) in brands" :key="index">{{brand.name_en}}</option>
          </select>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12" sstyle="padding: 0 19px 0 19px">
          <label for="inquiry">Inquiry Type</label>
          <select id="inquiry" v-model="inquiry_type">
            <option value="0" disabled>Choose Inquiry Type</option>
            <option value="1">Inquiry</option>
            <option value="2">Complaint</option>
          </select>
        </vs-col>
        <vs-col vs-lg="12" sstyle="padding: 0 19px 0 19px">
          <div class="input">
            <vs-textarea id="emails" v-model="emails" class="emails" label="Emails"/>
          </div>
        </vs-col>
      </vs-row>
      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          :disabled="invalidForm"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="$emit('cancel')"
        >Cancel
        </vs-button>
      </div>

    </vx-card>


  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    components: {Breadcrumb},
    name: "add-settings",
    data() {
      return {
        brands: [],
        data: [],
        brand_id: "0",
        inquiry_type: "0",
        brand_name: "Brand Name",
        emails: ""
      }
        ;
    },
    methods: {
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars.get(`/brands`)
          .then(res => {
            this.brands = res.data.data.brands;
            this.$vs.loading.close();
            //console.log(res);
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch data from the server",
              color: "danger"
            });
          });
      },
      saveData() {

        this.$vs.loading();

        let emails = this.emails.split(",");

        let payload = {
          emails: emails,
          inquiry_types_id: this.inquiry_type,
          brand_name: this.brand_name,
          brand_id: this.brand_id
        };

        for (let i in emails) {
          if (!this.validateEmail(emails[i])) {
            this.$vs.notify({
              title: "Error",
              text: "There is an invalid email found.\nPlease, check all emails again",
              color: "danger"
            });
            this.$vs.loading.close();
            return;
          }
        }

        this.$httpAdmin.post(`inquiry-brand`, payload)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data Saved Successfully",
              color: "success"
            });
            this.$emit("saved");
          })
          .catch(e => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.message === "Request failed with status code 400" ? "This brand and inquiry type has emails , you can edit them" : "Couldn't Save Data!",
              color: "danger"
            });
          });
      },
      validateEmail(email) {
        let re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(String(email).toLowerCase());
      }
      ,
    },
    watch: {
      brand_id(value) {
        for (let i in this.brands) {
          if (this.brands[i].id === value) {
            this.brand_name = this.brands[i].name_en;
            break;
          }
        }
      }
    }
    ,
    computed: {
      invalidForm() {
        if (
          this.emails &&
          this.inquiry_type !== '0' &&
          this.brand_name &&
          this.brand_id !== '0'
        ) {
          return false
        }
        return true;
      }
    }
    ,
    mounted() {
      this.getBrands();
    }
  }
  ;
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }

  .input {
    width: 98%;
    margin-left: 20px;
  }

  .emails {
    height: 175px;
  }

  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
  }
</style>
