<template>
    <div>
        <breadcrumb
            name="Contact Us"
            title="Contact Us Requests"
            subtitle="View"
            @onTitleClicked="$emit('cancel')"
        />
        <vx-card title="Details" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">User Name</td>
                                <td class="p-4">
                                    {{ request.name || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">User Email</td>
                                <td class="p-4">
                                    {{ request.email || "Undefined" }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">User Mobile</td>
                                <td class="p-4">
                                    {{ request.mobile || "Undefined" }}
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td class="font-semibold p-4">Inquiry Type</td>
                                <td class="p-4">
                                    {{ request.inquiry_type || "Undefined" }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <td class="font-semibold p-4">Send At</td>
                            <td class="p-4">{{ request.created_at }}</td>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">VIN Number</td>
                                <td class="p-4">
                                    {{ request.vin_number || "Guest User" }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <td class="font-semibold p-4">Comment</td>
                            <td class="p-4">{{ request.comment }}</td>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <td class="font-semibold p-4">Status</td>
                            <td class="">
                                <vs-chip
                                    style="position: relative; top: 10px"
                                    :color="
                                        getAgentStatusColor(
                                            request.agent_status
                                                ? request.agent_status
                                                : 'None'
                                        )
                                    "
                                    class="product-order-status text-center"
                                >
                                    {{
                                        getAgentStatusState(
                                            request.agent_status
                                                ? request.agent_status
                                                : "None"
                                        )
                                    }}
                                </vs-chip>
                            </td>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto">
                        <table class="sm-table">
                            <td class="font-semibold p-4">Agent Comment</td>
                            <td class="p-4" v-html="request.agent_comment"></td>
                        </table>
                    </div>
                </vs-col>
            </vs-row>

            <!-- <tr>
                <td class="font-semibold p-4">
                    Agent Name
                </td>
                <td class="p-4">
                    <span
                        v-if="
                            request.agent_status && request.agent_status.admin
                        "
                    >
                        {{ request.agent_status.admin.name }}
                    </span>
                    <vs-td v-else>None</vs-td>
                </td>
            </tr>
            <tr>
                <td class="font-semibold p-4">
                    Agent Email
                </td>
                <td class="p-4">
                    <span
                        v-if="
                            request.agent_status && request.agent_status.admin
                        "
                    >
                        {{ request.agent_status.admin.email }}
                    </span>
                    <vs-td v-else>None</vs-td>
                </td>
            </tr> -->
        </vx-card>

        <vx-card title="Update Status" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold p-4">
                                    Request Status
                                </td>
                                <td class="p-4">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                    <feather-icon
                                        icon="EditIcon"
                                        svgClasses="w-6 mt-1 h-6 mr-2 hover:text-warning stroke-current"
                                        @click="
                                            agent_status_update =
                                                !agent_status_update
                                        "
                                        class="ml-12"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    v-if="agent_status_update"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                >
                    <label>Status</label>
                    <select v-model="agent_status">
                        <option value="pending">Pending</option>
                        <option value="in_progress">In Progress</option>
                        <option value="done">Done</option>
                        <option value="not_reachable">Not Reachable</option>
                    </select>
                </vs-col>
            </vs-row>
             <div class="english" v-if="agent_status_update">
                <small class="label">Agent Comment</small>
                <ckeditor
                    :editor="editor"
                    v-model="agent_comment"
                    :config="editorConfigEn"
                    class="input"
                    v-if="!showHTML"
                />
                <textarea v-model="agent_comment" v-if="showHTML"></textarea>
            </div>
            <div class="optionBtns" v-if="agent_status_update">
                <vs-button
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="changeAgentStatus"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="agent_status_update = false"
                    >Cancel
                </vs-button>
            </div>
        </vx-card>
        <vs-button
            color="primary"
            type="filled"
            style="float: right"
            @click="$emit('cancel')"
            >Back
        </vs-button>
        <div class="clr" />
    </div>
</template>

<script>
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "view-request",
    components: {
        Breadcrumb,
        ChevronsRightIcon,
        HomeIcon,
    },
    props: {
        request: {
            required: true,
            default: {
                id: null,
                name: "",
                email: "",
                comment: "",
                inquiry_type: "",
                agent_status: "",
                agent_comment: ''
            },
        },
    },
    data() {
        return {
            agent_status: this.request.agent_status,
            agent_status_update: false, agent_comment: '', showHTML: false,
            editorConfigEn: {
                placeholder: "Agent Comment",
                language: "en",
            },
            editor: ClassicEditor,
        };
    },
    computed: {
        isActive() {
            if (this.request.status === "active") {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        activated(val) {
            this.$httpAbout
                .get(`sparepart/activation/${this.part.id}/mobile`)
                .then((r) => {
                    console.log(r);
                    this.$emit("update");
                    if (val) {
                        this.$vs.notify({
                            title: "Success",
                            text: "Item is activated successfully",
                            color: "primary",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Success",
                            text: "Item is deactivated successfully",
                            color: "primary",
                        });
                    }
                })
                .catch(() => {
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger",
                    });
                });
        },
    },
    methods: {
        changeAgentStatus() {
            const payload = {
                request_id: this.request.id,
                request_type: "contact_us",
                status: this.agent_status,
                agent_comment: this.agent_comment 
            };
            console.log(this.$store.state.auth.token);
            this.$httpAdmin
                .post(`/requests/change-status`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Request updated",
                        text: "Request updated successfully!",
                        color: "success",
                    });
                    location.reload();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getAgentStatusColor(status) {
            if (status == "pending") return "dark";
            if (status == "in_progress") return "primary";
            if (status == "done") return "success";
            if (status == "not_reachable") return "warning";
            return "danger";
        },
        getAgentStatusState(status) {
            if (status == "pending") return "Pending";
            if (status == "in_progress") return "In Progress";
            if (status == "done") return "Done";
            if (status == "not_reachable") return "Not Reachable";
            return "Unknown";
        },
    },
};
</script>

<style scoped lang="scss">
.status {
    margin: 0 50px 50px 0;
    float: right;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

select {
    width: 90%;
    margin-left: 15px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.select {
    width: 25%;
    float: right;
    margin-bottom: 30px;
}

.active {
    background-color: #28c76f;
}

.cancelled {
    background-color: #ff9f43;
}

.clr {
    clear: both;
}

.name {
    font-weight: 900;
}

.main-component {
    cursor: pointer;
}

.reason {
    visibility: hidden;
}

.inline {
    display: inline-block;
}
</style>
