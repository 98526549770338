<template>
  <div>
    <add-settings v-if="addNew && !viewRequest" @cancel="addNew = false" @saved="getSettings"/>
    <edit-settings v-if="viewRequest && !addNew" :item="item" @cancel="viewRequest = false" @saved="getSettings"/>
    <div v-if="!viewRequest && !addNew">

      <breadcrumb name="Contact Us" title="Contact Us Settings" subtitle="List"/>

      <div id="data-list-thumb-view" class="data-list-container">

        <vs-table ref="table" pagination :max-items="itemsPerPage" search
                  :data="settings">

          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

            <div class="flex flex-wrap-reverse items-center">

              <add-new-button @click="addItem"/>

            </div>

            <!-- ITEMS PER PAGE -->
            <table-header
              :current-page="currentPage"
              :items-per-page="itemsPerPage"
              :length="products.length"
              :queried-items="queriedItems"
              @onChangeItemsPerPage="itemsPerPage = $event"/>


          </div>

          <template slot="thead">
            <vs-th sort-key="name">Brand Name</vs-th>
            <vs-th sort-key="name">Inquiry Type</vs-th>
            <vs-th sort-key="mobile">Emails</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <string-row :data="tr.brand_name "/>
              <string-row :data="tr.inquiry"/>
              <string-row :data="tr.email.toString()"/>
              <actions-row :show="false" @onDeleteClicked="deleteData(tr.id)" @onEditClicked="viewItem(tr)"/>


            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { ChevronsRightIcon, EyeIcon, HomeIcon } from "vue-feather-icons";
  import ViewRequest from "./view-contact-us-request";
  import Breadcrumb from "../../../components/general/breadcrumb";
  import TableHeader from "../../../components/table/table-header";
  import StringRow from "../../../components/table/rows/string-row";
  import ActionsRow from "../../../components/table/rows/actions-row";
  import EditSettings from "./edit-settings";
  import AddNewButton from "../../../components/form/addnew-button";
  import AddSettings from "./add-settings";

  export default {
    name: "contact-us",
    components: {
      AddSettings,
      AddNewButton,
      EditSettings,
      ActionsRow,
      StringRow,
      TableHeader,
      Breadcrumb,
      HomeIcon,
      ChevronsRightIcon,
      EyeIcon,
      ViewRequest
    },
    data() {
      return {
        request: {
          id: null,
          name: "",
          email: "",
          comment: "",
          inquiry_type: ""
        },
        item: {},
        requests: [],
        settings: [],
        requestItem: {},
        selected: [],
        mainData: {},
        itemsPerPage: 20,
        isMounted: false,
        addNewDataSidebar: false,
        sidebarData: {},
        viewRequest: false,
        addNew: false
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      products() {
        return this.requests;
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length;
      }
    },
    methods: {
      viewItem(item) {
        this.item = item;
        this.viewRequest = true;
        console.log(item);
      },
      addItem() {
        this.addNew = true;
      },
      deleteData(id) {
        this.$vs.loading();
        this.$httpAdmin.delete(`inquiry-brand/${id}`)
          .then(r => {
            console.log(r);
            this.$vs.loading();
            this.$vs.notify({
              title: "Success",
              text: "Data is deleted successfully",
              color: "success"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't delete Data!",
              color: "danger"
            });
          });
      },
      getSettings() {
        this.$vs.loading();
        this.viewRequest = false;
        this.addNew = false;
        this.$httpAdmin.get("inquiry-brand")
          .then(r => {
            this.$httpAdmin.get(`inquiry-brand?per_page=${r.data.data.total}`)
              .then(r => {
                this.settings = r.data.data.data;
                console.log({ contactSettings: this.settings });
                this.$vs.loading.close();
              })
              .catch(() => {
                this.$vs.loading.close();
                //console.log(e);
                this.$vs.notify({
                  title: "Error",
                  text: "Couldn't Load Data!",
                  color: "danger"
                });
              });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Load Data!",
              color: "danger"
            });
          });
      },
      openLink(link) {
        if (!/^http/.test(link)) {
          window.open("http://" + link, "_blank");
        } else {
          window.open(link, "_blank");
        }

      },
      getOrderStatusColor(status) {
        if (status == "cancelled" || status == "canceled") return "warning";
        if (status == "active") return "success";
        return "primary";
      },
      getOrderStatusState(status) {
        if (status == 0) return "Unavailable";
        if (status == 1) return "Available";
        return "Unknown";
      }
    },
    mounted() {
      this.isMounted = true;
      this.getSettings();
    }
  };
</script>

<style lang="scss">

  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }


  #data-list-thumb-view {
    .vs-con-table {

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;


        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 10px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }

            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 110px;
              }
            }
          }

          td.td-check {
            padding: 20px !important;
            display: none !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
          display: none !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
